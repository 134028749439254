export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"content":"Unlock the full potential of your accumulated knowledge.","name":"description"},{"content":"#121212","name":"theme-color"},{"property":"og:url","content":"https://octo.app"},{"property":"og:type","content":"website"},{"property":"og:title","content":"Octo | Write meaningful notes"},{"property":"og:description","content":"Unlock the full potential of your accumulated knowledge."},{"property":"og:image","content":"https://octo.app/img/og.png"},{"name":"twitter:card","content":"summary_large_image"},{"property":"twitter:domain","content":"octo.app"},{"property":"twitter:url","content":"https://octo.app"},{"name":"twitter:title","content":"Octo | Write meaningful notes"},{"name":"twitter:description","content":"Unlock the full potential of your accumulated knowledge."},{"name":"twitter:image","content":"https://octo.app/img/og.png"}],"link":[{"href":"/img/icons/favicon.ico","rel":"icon"},{"href":"https://fonts.googleapis.com","rel":"preconnect"},{"crossorigin":"","href":"https://fonts.gstatic.com","rel":"preconnect"},{"href":"https://fonts.googleapis.com/css2?family=Fira+Code&family=Inter:wght@300;400;600;700&display=swap","rel":"stylesheet"},{"href":"/img/icons/icon-maskable-192x192.png","rel":"apple-touch-icon"}],"style":[],"script":[{"data-auto":false,"data-site":"NPCCHOAZ","defer":true,"src":"https://twelve-intellectual.octo.app/script.js"}],"noscript":[],"htmlAttrs":{"class":"scroll-smooth scroll-pt-4 layer-0","lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false