import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 250 250",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M249.836 124.771c0 68.909-55.861 124.771-124.771 124.771-68.909 0-124.77-55.862-124.77-124.771C.294 55.861 56.155 0 125.064 0c68.91 0 124.771 55.862 124.771 124.771Zm-48.194 46.899c0 15.59-9.968 28.228-22.264 28.228s-22.264-12.638-22.264-28.228c0-15.59 9.968-28.228 22.264-28.228s22.264 12.638 22.264 28.228Zm-108.625 0c0 15.59-9.968 28.228-22.264 28.228S48.49 187.26 48.49 171.67c0-15.59 9.968-28.228 22.264-28.228s22.264 12.638 22.264 28.228Z\" fill=\"#F66\"></path><mask id=\"a\" style=\"mask-type:alpha;\" maskUnits=\"userSpaceOnUse\" x=\"145\" y=\"117\" width=\"68\" height=\"85\"><ellipse cx=\"179.378\" cy=\"159.462\" rx=\"33.429\" ry=\"42.383\" fill=\"#D9D9D9\"></ellipse></mask><g mask=\"url(#a)\"><ellipse cx=\"179.378\" cy=\"159.462\" rx=\"33.429\" ry=\"42.383\" fill=\"#fff\"></ellipse><ellipse cx=\"163.865\" cy=\"162.805\" rx=\"23.367\" ry=\"29.625\" fill=\"#000\"></ellipse></g><mask id=\"b\" style=\"mask-type:alpha;\" maskUnits=\"userSpaceOnUse\" x=\"37\" y=\"117\" width=\"68\" height=\"85\"><ellipse cx=\"70.754\" cy=\"159.462\" rx=\"33.429\" ry=\"42.384\" fill=\"#D9D9D9\"></ellipse></mask><g mask=\"url(#b)\"><ellipse cx=\"70.754\" cy=\"159.462\" rx=\"33.429\" ry=\"42.384\" fill=\"#fff\"></ellipse><ellipse cx=\"86.05\" cy=\"162.271\" rx=\"23.366\" ry=\"29.625\" fill=\"#000\"></ellipse></g>", 5)
  ])))
}
export default { render: render }