import revive_payload_client_GlBFRPWG9P from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_jcnpjpwtqkwavawf2dl7scerty/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import path_client_2CrkUkjKpA from "/home/runner/work/octo/octo/plugins/path.client.ts";
import unhead_iH8E30rqc8 from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_jcnpjpwtqkwavawf2dl7scerty/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zSoPyKSS6y from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_jcnpjpwtqkwavawf2dl7scerty/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_1PqoMaNqnK from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_jcnpjpwtqkwavawf2dl7scerty/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_QuudOYoy99 from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_jcnpjpwtqkwavawf2dl7scerty/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_1vXaNIWkVf from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_jcnpjpwtqkwavawf2dl7scerty/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_zmyzAoN12Q from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_jcnpjpwtqkwavawf2dl7scerty/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_0eFgIQf4xF from "/home/runner/work/octo/octo/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@2.79.1_typescript@5.3.3_vue@3.5.3_typescript@5.3.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/octo/octo/.nuxt/components.plugin.mjs";
import prefetch_client_OKahxKPB2y from "/home/runner/work/octo/octo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@18.19.4_encoding@0.1.13_eslint@8.56.0_ioredis@5_jcnpjpwtqkwavawf2dl7scerty/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_fmZxzmJZPz from "/home/runner/work/octo/octo/node_modules/.pnpm/@vite-pwa+nuxt@0.4.0_magicast@0.3.5_rollup@2.79.1_vite@5.4.3_@types+node@18.19.4_terser@5.31._y6c6lqj5mr75wrubjoyzwntvqq/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import dev_Zc585R5Y2V from "/home/runner/work/octo/octo/plugins/dev.ts";
import mq_9h3c4HK3CS from "/home/runner/work/octo/octo/plugins/mq.ts";
import plugins_gZVkQZouNK from "/home/runner/work/octo/octo/plugins/plugins.ts";
import vuex_F8JCgj45hr from "/home/runner/work/octo/octo/plugins/vuex.ts";
export default [
  revive_payload_client_GlBFRPWG9P,
  path_client_2CrkUkjKpA,
  unhead_iH8E30rqc8,
  router_zSoPyKSS6y,
  payload_client_1PqoMaNqnK,
  navigation_repaint_client_QuudOYoy99,
  check_outdated_build_client_1vXaNIWkVf,
  chunk_reload_client_zmyzAoN12Q,
  plugin_vue3_0eFgIQf4xF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_OKahxKPB2y,
  pwa_client_fmZxzmJZPz,
  dev_Zc585R5Y2V,
  mq_9h3c4HK3CS,
  plugins_gZVkQZouNK,
  vuex_F8JCgj45hr
]