import { default as _routesyg91tI4vjWMeta } from "/home/runner/work/octo/octo/pages/_routes.vue?macro=true";
import { default as accountVZU8Xdy2SPMeta } from "/home/runner/work/octo/octo/pages/account.vue?macro=true";
import { default as _91id_93a60Sy2MXwSMeta } from "/home/runner/work/octo/octo/pages/assistant/conversations/[id].vue?macro=true";
import { default as indexI2gVpkE3GZMeta } from "/home/runner/work/octo/octo/pages/assistant/conversations/index.vue?macro=true";
import { default as indexmbUMDv0RqhMeta } from "/home/runner/work/octo/octo/pages/assistant/index.vue?macro=true";
import { default as newTU2giXVaKGMeta } from "/home/runner/work/octo/octo/pages/assistant/system-instructions/new.vue?macro=true";
import { default as authenticateqQ7b6fyIRaMeta } from "/home/runner/work/octo/octo/pages/authenticate.vue?macro=true";
import { default as indexGaW4dVzU0fMeta } from "/home/runner/work/octo/octo/pages/dev/design-system/index.vue?macro=true";
import { default as indexVIo8Jvrr2uMeta } from "/home/runner/work/octo/octo/pages/dev/design-system/themes/index.vue?macro=true";
import { default as indexzYBbhD4ODIMeta } from "/home/runner/work/octo/octo/pages/docs/[docId]/index.vue?macro=true";
import { default as metaVM1jnM0w27Meta } from "/home/runner/work/octo/octo/pages/docs/[docId]/meta.vue?macro=true";
import { default as exportzPFbQRabQNMeta } from "/home/runner/work/octo/octo/pages/docs/export.vue?macro=true";
import { default as _91filter_932P0WvCefW6Meta } from "/home/runner/work/octo/octo/pages/docs/f/[filter].vue?macro=true";
import { default as importy1jZ0rOq5vMeta } from "/home/runner/work/octo/octo/pages/docs/import.vue?macro=true";
import { default as indexV84GLTDcG8Meta } from "/home/runner/work/octo/octo/pages/docs/index.vue?macro=true";
import { default as _91_46_46_46tag_93Jn7bNjp5lCMeta } from "/home/runner/work/octo/octo/pages/docs/t/[...tag].vue?macro=true";
import { default as _91file_93E9V7pkZmFjMeta } from "/home/runner/work/octo/octo/pages/file-editor/[file].vue?macro=true";
import { default as force_45graph8WEBpvF9PEMeta } from "/home/runner/work/octo/octo/pages/force-graph.vue?macro=true";
import { default as indexytJzpEg7u6Meta } from "/home/runner/work/octo/octo/pages/index.vue?macro=true";
import { default as menus21kfQeSPUMeta } from "/home/runner/work/octo/octo/pages/menu.vue?macro=true";
import { default as notepadQVaPqingHSMeta } from "/home/runner/work/octo/octo/pages/notepad.vue?macro=true";
import { default as privacy_45policyKOn8P6cP28Meta } from "/home/runner/work/octo/octo/pages/privacy-policy.vue?macro=true";
import { default as pricingFt9HebWJQUMeta } from "/home/runner/work/octo/octo/pages/product/pricing.vue?macro=true";
import { default as _91docId_933TfxhGmYDTMeta } from "/home/runner/work/octo/octo/pages/public/[docId].vue?macro=true";
import { default as settingssesMVV9TAwMeta } from "/home/runner/work/octo/octo/pages/settings.vue?macro=true";
import { default as tagsOPbggLn4tRMeta } from "/home/runner/work/octo/octo/pages/tags.vue?macro=true";
import { default as terms_45and_45conditionsPbGDhgL3M5Meta } from "/home/runner/work/octo/octo/pages/terms-and-conditions.vue?macro=true";
import { default as workspaces5agLtq3QGjMeta } from "/home/runner/work/octo/octo/pages/workspaces.vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
export default [
  {
    name: "_routes",
    path: "/_routes",
    component: () => import("/home/runner/work/octo/octo/pages/_routes.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/home/runner/work/octo/octo/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "assistant-conversations-id",
    path: "/assistant/conversations/:id()",
    component: () => import("/home/runner/work/octo/octo/pages/assistant/conversations/[id].vue").then(m => m.default || m)
  },
  {
    name: "assistant-conversations",
    path: "/assistant/conversations",
    component: () => import("/home/runner/work/octo/octo/pages/assistant/conversations/index.vue").then(m => m.default || m)
  },
  {
    name: "assistant",
    path: "/assistant",
    component: () => import("/home/runner/work/octo/octo/pages/assistant/index.vue").then(m => m.default || m)
  },
  {
    name: "assistant-system-instructions-new",
    path: "/assistant/system-instructions/new",
    component: () => import("/home/runner/work/octo/octo/pages/assistant/system-instructions/new.vue").then(m => m.default || m)
  },
  {
    name: "authenticate",
    path: "/authenticate",
    meta: authenticateqQ7b6fyIRaMeta || {},
    component: () => import("/home/runner/work/octo/octo/pages/authenticate.vue").then(m => m.default || m)
  },
  {
    name: "dev-design-system",
    path: "/dev/design-system",
    component: () => import("/home/runner/work/octo/octo/pages/dev/design-system/index.vue").then(m => m.default || m)
  },
  {
    name: "dev-design-system-themes",
    path: "/dev/design-system/themes",
    component: () => import("/home/runner/work/octo/octo/pages/dev/design-system/themes/index.vue").then(m => m.default || m)
  },
  {
    name: "docs-docId",
    path: "/docs/:docId()",
    component: () => import("/home/runner/work/octo/octo/pages/docs/[docId]/index.vue").then(m => m.default || m)
  },
  {
    name: "docs-docId-meta",
    path: "/docs/:docId()/meta",
    component: () => import("/home/runner/work/octo/octo/pages/docs/[docId]/meta.vue").then(m => m.default || m)
  },
  {
    name: "docs-export",
    path: "/docs/export",
    component: () => import("/home/runner/work/octo/octo/pages/docs/export.vue").then(m => m.default || m)
  },
  {
    name: "docs-f-filter",
    path: "/docs/f/:filter()",
    component: () => import("/home/runner/work/octo/octo/pages/docs/f/[filter].vue").then(m => m.default || m)
  },
  {
    name: "docs-import",
    path: "/docs/import",
    component: () => import("/home/runner/work/octo/octo/pages/docs/import.vue").then(m => m.default || m)
  },
  {
    name: "docs",
    path: "/docs",
    component: () => import("/home/runner/work/octo/octo/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: "docs-t-tag",
    path: "/docs/t/:tag(.*)*",
    component: () => import("/home/runner/work/octo/octo/pages/docs/t/[...tag].vue").then(m => m.default || m)
  },
  {
    name: "file-editor-file",
    path: "/file-editor/:file()",
    component: () => import("/home/runner/work/octo/octo/pages/file-editor/[file].vue").then(m => m.default || m)
  },
  {
    name: "force-graph",
    path: "/force-graph",
    component: () => import("/home/runner/work/octo/octo/pages/force-graph.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexytJzpEg7u6Meta || {},
    component: () => import("/home/runner/work/octo/octo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "menu",
    path: "/menu",
    component: () => import("/home/runner/work/octo/octo/pages/menu.vue").then(m => m.default || m)
  },
  {
    name: "notepad",
    path: "/notepad",
    component: () => import("/home/runner/work/octo/octo/pages/notepad.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyKOn8P6cP28Meta || {},
    component: () => import("/home/runner/work/octo/octo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "product-pricing",
    path: "/product/pricing",
    meta: pricingFt9HebWJQUMeta || {},
    component: () => import("/home/runner/work/octo/octo/pages/product/pricing.vue").then(m => m.default || m)
  },
  {
    name: "public-docId",
    path: "/public/:docId()",
    component: () => import("/home/runner/work/octo/octo/pages/public/[docId].vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/home/runner/work/octo/octo/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "tags",
    path: "/tags",
    component: () => import("/home/runner/work/octo/octo/pages/tags.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    meta: terms_45and_45conditionsPbGDhgL3M5Meta || {},
    component: () => import("/home/runner/work/octo/octo/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "workspaces",
    path: "/workspaces",
    component: () => import("/home/runner/work/octo/octo/pages/workspaces.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: indexp9V2p5fxycMeta || {},
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  }
]